<template>
  <el-row class="movie-list">
    <el-col :md="20">
      <el-row>
        <h3>收货地址</h3>
        <el-divider />
        <div v-for="(item, index) in addresses" :key="index">
          <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
            <el-radio v-model="item.radio" label="1">{{ item.address }}</el-radio>
          </el-row>
        </div>
      </el-row>
      <el-row>
        <h3>订单金额</h3>
        <el-divider />
        <el-table
          :data="dataList"
          :show-header="true"
          border
          style="width: 100%"
        >
          <el-table-column
            fixed="left"
            label="商品信息"
          >
            <template slot-scope="scope">
              <el-row>
                <el-col :md="4">
                  <el-image :src="scope.row.picUrl" min-width="30" height="20" />
                </el-col>
                <el-col :md="20">
                  <router-link target="_blank" style="text-decoration-line: none" :to="`/mall/item?id=${scope.row.productId}`">
                    <span>{{ scope.row.title }}</span>
                  </router-link>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column
            prop="sku"
            label="sku"
          />
          <el-table-column
            label="单价"
          >
            <template slot-scope="scope">
              <span style="color: red">￥{{ scope.row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="amount"
            label="数量"
          />
          <el-table-column
            prop="num"
            label="小计"
          />
        </el-table>
      </el-row>
      <el-row>
        <el-card shadow="hover" style="width: 100%;">
          <div style="width: 100%; display: inline-block; ">
            <el-statistic
              :value="deadline2"
              format="HH:mm:ss"
              time-indices
              title="🎉付款倒计时🎉"
              @finish="hilarity"
            >
              <template slot="prefix">
                请在
              </template>
              <template slot="suffix">
                分钟内付款
              </template>
            </el-statistic>
          </div>
        </el-card>
      </el-row>
      <el-row>
        <el-form>
          <el-form-item>
            <span style="position: relative; bottom: 0; right: 0; color:blue">
              已选商品 <span style="color: red">{{ dataList.length }}</span> 件
            </span>
            <span style="position: relative; bottom: 0; right: 0; color:blue">
              合计（不含运费）： <span style="color: red">{{ totalPrice }}</span> 元
            </span>
            <el-button
              size="mini"
              type="danger"
              @click="payOrder"
            >
              支付订单
            </el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import { getOrder, payOrder } from '@/api/mall'

export default {
  name: 'Pay',
  data() {
    return {
      radio: '1',
      addresses: [],
      dataList: [],
      totalPrice: 0.00,
      order: null,
      deadline2: Date.now() + 1000 * 60 * 15
    }
  },
  created() {
    const orderId = this.$route.query.orderId
    getOrder(orderId).then(resp => {
      if (resp.code === 0) {
        this.order = resp.data
        this.totalPrice = this.order.price * this.order.amount
        this.dataList.push(this.order)
      }
    })

    document.title = '支付订单'
  },
  methods: {
    hilarity() {
      this.$message.info('订单超时')
    },
    payOrder() {
      payOrder(this.order.orderId).then(resp => {
        if (resp.code === 0) {
          const loading = this.$loading({
            lock: true,
            text: '订单已支付, 跳转到已购买商品页面',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          setTimeout(() => {
            loading.close()
            this.$router.push({
              path: '/mall/order'
            })
          }, 1000)
        } else {
          this.$notify.error({
            title: '提示',
            message: resp.msg
          })
          setTimeout(() => {
            this.$router.push({
              path: '/mall/order'
            })
          }, 1000)
        }
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
